import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import SwiperCore, { EffectCards } from 'swiper';
import one from '../../images/1.png';
import two from '../../images/2.png';
import three from '../../images/3.png';
import four from '../../images/4.png';
import five from '../../images/5.png';
import six from '../../images/6.png';
import seven from '../../images/7.png';
import eight from '../../images/8.png';
import nine from '../../images/9.png';
import ten from '../../images/10.png';
import eleven from '../../images/11.png';
import twelve from '../../images/12.png';
import thriteen from '../../images/13.png';
import fourteen from '../../images/14.png';
import fifteen from '../../images/15.png';
import sixteen from '../../images/16.png';
import seventeen from '../../images/17.png';
import eighteen from '../../images/18.png';

SwiperCore.use([EffectCards]);

function Slider() {
  return (
    <div className="swiper-container" style={{ width: '100%', height: '600px' }}>
      <Swiper effect={'cards'} grabCursor={true} className="mySwiper">
        <SwiperSlide>
          <img className="swiper-image" src={one} alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={two} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={three} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={four} alt="Slide 4" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={five} alt="Slide 5" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={six} alt="Slide 6" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={seven} alt="Slide 7" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={eight} alt="Slide 8" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={nine} alt="Slide 9" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={ten} alt="Slide 10" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={eleven} alt="Slide 11" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={twelve} alt="Slide 12" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={thriteen} alt="Slide 13" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={fourteen} alt="Slide 14" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={fifteen} alt="Slide 15" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={sixteen} alt="Slide 16" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={seventeen} alt="Slide 17" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="swiper-image" src={eighteen} alt="Slide 18" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Slider;
