import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

import 'aos/dist/aos.css';
import AOS from 'aos';

import screw from '../../images/screw.png';
import metalscrew from '../../images/metalscrew.png';
import rawlplug from '../../images/rawl.png';
import connecting from '../../images/connecting.png';
import angle from '../../images/angle.png';
import edge from '../../images/edge.png';
import shadow from '../../images/shadow.png';
import sofit from '../../images/sofit.png';
import access from '../../images/access.png';
import celing from '../../images/celing.png';
import perimeter from '../../images/permiter.png';
import ceilinganle from '../../images/celingangle.png';
import { Helmet } from 'react-helmet';
import favicon from '../../images/logo2.png'


import { useEffect } from 'react';





const Accessories: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>


      <Helmet>
        <title>Accessories - High-Quality Materials</title>
        <meta name="description" content="Discover our range of high-quality accessories for building and construction, including drywall screws, metal screws, rawl plugs, and more." />
        <link rel="icon" href={favicon} /> 
        <meta name="keywords" content="drywall screws, metal screws, rawl plugs, connecting clips, angle bead, edge bead, shadow line stopping bead, soffit cleat, ceiling section, perimeter channel, intermediate channel, building materials, construction accessories" />
      </Helmet>
     <Navbar></Navbar>



<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-0 pt-20 ">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none text-slate-800 mb-6">
                    
                   Drywall screw
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
               Corrosion resistance
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Cross head
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
             Fix plasterboard to metal frame work
                </li>
               
    <div className="flex justify-center">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">3.5 x 2.5 ,<br/>3.5 x 35 ,<br/>3.5 x 50 </td>
          </tr>
        </tbody>
      </table>
    
  </div>
                <div className="flex mt-8 gap-3">

                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
              
                    <img src={screw} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-left"/>
               
           </div>

        </div>
    </div>
</main>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2" data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-bebas-neue uppercase text-6xl sm:text-5xl font-black flex flex-col leading-none mb-6">
                  Metal to metal screws
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Used in  channel screwing
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Metal screw with water thin head
                </li>

                <div className="flex ">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">4.2 x 13</td>
          </tr>
        </tbody>
      </table>
    </div>

                <div className="flex mt-8 gap-3">

                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1" >
            <img src={metalscrew} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto  " data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-7xl font-black flex flex-col leading-none ">
                    
                   Rawl plug
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                     Expandable Faster to hold ceiling
                </li>
              

                <div className="flex ">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">8 x 45 </td>
          </tr>
        </tbody>
      </table>
    </div>
                <div className="flex mt-8 gap-3">
                    
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={rawlplug} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-bebas-neue uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6">
                   Connecting clip
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                Used to connect ceiling section.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                U section to intermediate channel or tube section
                </li>
                <div className="flex ">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">3.5</td>
          </tr>
        </tbody>
      </table>
    </div>
                <div className="flex mt-8 gap-3">
                
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1">
            <img src={connecting} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto  " data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-0 pt-20">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none text-slate-800 mb-6">
                    
                   Angle bead
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
            Perforated galvanized metal bead
                </li>
      <div className="flex ">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">25 x 25 x 0.5 x 2440,<br/>25 x 25 x 0.5 x 3660</td>
          </tr>
        </tbody>
      </table>
    </div>
                <div className="flex mt-8 gap-3">
                  
            
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2 ">
    <img src={angle} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto " data-aos="zoom-in-left"/>
</div>
        </div>
    </div>
</main>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2" data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none ">
                  Edge bead
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
            Asymmectric profile
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
            Used to protect  exposed  and unsupported edges of plasterboard
                </li>

    <div className="flex">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">25 x 10 x 9.5 x 0.5 x 2440,<br/>25 x 10 x 12.5 x 0.5 x 2440,<br/>25 x 10 x 15 x 0.5 x 2440,<br/>25 x 15 x 9.5 x 0.5 x 3660,<br/>25 x 10 x 9.5 x 0.5 x 3660</td>
          </tr>
        </tbody>
      </table>
    </div>    
     <div className="flex mt-8 gap-3">
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1" >
            <img src={edge} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6">
                    
                   shadow line stopping bead
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
            Used to  minimizing  the apperance of  non- aligned  walls and ceiling.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Suitable for door jambs, windows,ceiling perimeters etc.
                </li>
                
    <div className="flex">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">10 x 9 x 28 x 0.4</td>
          </tr>
        </tbody>
      </table>
    </  div>    
                <div className="flex mt-8 gap-3">
                   <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={shadow} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto " data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-bebas-neue uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6 ">
                   Soffit cleat
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
               Hold ceiling angle/ ms flat/level clip suspension of framework.
                </li>
               
    <div className="flex">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">7 x 37 x 25 x 1.6</td>
          </tr>
        </tbody>
      </table>
    </div>    
                <div className="flex mt-8 gap-3">
                  

                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1">
            <img src={sofit} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6">
                    
                   ceiling section
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                    Main supporting section to flix plasterboard.
                </li>
                     <div className="flex ">
                       <table className="table-auto mb-8 mt-10">
                         <tbody>
                           <tr className="bg-gray-100 font-semibold text-xl">
                             <td className="border px-4 py-2 ">Dimentions in mm</td>
                             <td className="border px-4 py-2">80 x 26 x 53 x 3660</td>
                           </tr>
                         </tbody>
                       </table>
                     </div>    
                <div className="flex mt-8 gap-3">
                  
              
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={celing} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2" data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none ">
                 perimeter channel
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
             Fixed around wall partition.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Coverage at a thickness of 13mm under laboratory conditions, packaged in 25 kg bags.
                </li>
                
                     <div className="flex ">
                       <table className="table-auto mb-8 mt-10">
                         <tbody>
                           <tr className="bg-gray-100 font-semibold text-xl">
                             <td className="border px-4 py-2 ">Dimentions in mm</td>
                             <td className="border px-4 py-2">20 x 28 x 30 x 3660</td>
                           </tr>
                         </tbody>
                       </table>
                     </div>    
                <div className="flex mt-8 gap-3">
                    
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1" >
            <img src={perimeter} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6">
                    
                   intermediate channel
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                Primary section to support the ceiling.
                </li>
                <div className="flex ">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">15 x 45 x 15 x 3660</td>
          </tr>
        </tbody>
      </table>
    </div>
                <div className="flex mt-8 gap-3">
                  
                    
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={shadow} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto " data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6">
                   ceiling angle
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                To provide suspension from structural soffit
                </li>
              
    <div className="flex ">
      <table className="table-auto mb-8 mt-10">
        <tbody>
          <tr className="bg-gray-100 font-semibold text-xl">
            <td className="border px-4 py-2 ">Dimentions in mm</td>
            <td className="border px-4 py-2">25 x 10 x 3660</td>
          </tr>
        </tbody>
      </table>
    </div>    
                <div className="flex mt-8 gap-3">
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1">
            <img src={ceilinganle} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto" data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none mb-6">
                    
                 Access Panel
                    
                </h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                  Area carrying electrical cables,HVAC ducts,valves and other conduit.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                 Consist of gypsum plasterboard fixed into a powdered coated alluminium frame structure with white RALL9001 finish.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                 Beaded frame is equipped with a push latch along with safety cables.
                </li>
                   <div className="flex ">
                    <table className="table-auto mb-8 mt-10">
                      <tbody>
                        <tr className="bg-gray-100 font-semibold text-xl">  
                          <td className="border px-4 py-2 ">Dimentions in mm</td>
                          <td className="border px-4 py-2">600 x 600,600 x 1200</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> 

                <div className="flex mt-8 gap-3">
                 
                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={access} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto " data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>


     <Footer></Footer>
    </>
  );
};

export default Accessories;
