import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { MdMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import image from '../../images/heading (1).png'
import favicon from '../../images/logo2.png'
import { Helmet } from 'react-helmet';


const Contact: React.FC = () => {
  return (
    <>
     
      <Helmet>
        <title>Contact Us - White Panther Gypsum</title>
        <meta name="description" content="Get in touch with White Panther Gypsum. Our experienced team is ready to engage with you. Contact us via email, phone, or visit our office." />
        <meta name="keywords" content="Contact, White Panther Gypsum, email, phone, office location, Mumbai,gypsum, gypsum products, gypsum solutions, gypsum powders, wall panels, White Panther Gypsum" />
        <link rel="icon" href={favicon} type="image/png" />
      </Helmet>
      <Navbar></Navbar>
      
    <div className="relative">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={image}
          alt=""
        />
        <div className="absolute inset-0 bg-slate-100 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
      <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none text-gray-50 sm:text-6xl md:mx-auto text-center shadow-2xl">


           Contact Us
            </h2>

      </div>
    </div>




      <div className="grid md:grid-cols-2 gap-16 items-center relative overflow-hidden p-10 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-3xl max-w-6xl mx-auto bg-white text-[#333] my-6 font-[sans-serif] before:absolute before:right-0 before:w-[300px] before:bg-blue-700 before:h-full max-md:before:hidden z-0 mb-44">
            <div>
                <h2 className="text-3xl font-extrabold">Get In Touch</h2>
                <p className="text-sm text-gray-400 mt-3">Our
                    experienced team is ready to engage with you.</p>
                    <form action="https://formsubmit.co/salesgypsum@hotmail.com" method="POST">
                 <div className="space-y-4 mt-8">
                   <input type="text" name="name" placeholder="Full Name" className="px-2 py-3 bg-white w-full text-lg border-b-2 border-x-transparent border-t-transparent focus:border-[#333] outline-none" required/>

                     <input type="email" name="email" placeholder="Email Id" className="px-2 py-3 bg-white w-full text-lg border-b-2 border-x-transparent border-t-transparent focus:border-[#333] outline-none" required/>

                     <input type="tel" name="phone" placeholder="Phone Number" className="px-2 py-3 bg-white w-full text-lg border-b-2 border-x-transparent border-t-transparent focus:border-[#333] outline-none" required/>

                      <textarea name="message" placeholder="Write Message" className="px-2 pt-3 bg-white text-black w-full text-lg border-b-2 focus:border-[#333] outline-none" required></textarea>
                 </div>
                <button type="submit" className="mt-8 flex items-center justify-center text-lg w-full rounded px-4 py-2.5 font-semibold bg-[#333] text-white hover:bg-[#222]">
                   <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#fff' className="mr-2" viewBox="0 0 548.244 548.244">
                    <path fillRule="evenodd" d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z" clipRule="evenodd" data-original="#000000" />
                    </svg>
                  Send Message
                </button>
</form>

                <ul className="mt-16 flex justify-center lg:space-x-6 max-lg:flex-col max-lg:items-center max-lg:space-y-2">
  <li className="flex items-center hover:text-red-500">
    <MdMailOutline className="text-4xl" />
    <a href="mailto:salesgypsum@hotmail.com" className="text-current text-lg ml-3 hover:text-red-500">
      <strong>salesgypsum@hotmail.com</strong>
    </a>
  </li>
  <li className="flex items-center text-current hover:text-blue-500">
    <IoCallOutline className="text-4xl" />
    <a href="tel:+919819600149" className="text-current text-lg ml-3 hover:text-blue-500">
      <strong>+91 9819600149</strong>
    </a>
  </li>
</ul>
<ul className="mt-4 flex justify-center lg:space-x-4 max-lg:flex-col max-lg:items-center max-lg:space-y-2 gap-4"> 
  <li className="flex items-center text-current hover:text-yellow-300 p-2 w-full mt-4">
    <IoLocationOutline className="text-8xl" />
    <a href="https://www.google.co.in/maps/place/KK+Chambers,+Dr+Dadabhai+Naoroji+Rd,+Azad+Maidan,+Fort,+Mumbai,+Maharashtra+400001/@18.935806,72.8332233,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7d1dc0d3b42ef:0xbb4045be9a75e71f!8m2!3d18.935806!4d72.8332233!16s%2Fg%2F1ptw_nts7?entry=ttu" target="_blank" rel="noopener noreferrer" className="text-current text-lg ml-3 hover:text-yellow-300">
      <strong>White Panther Gypsum, 3rd floor, K. K. Chambers, Fort, Mumbai - 400001</strong>
    </a>
  </li>
  <li className="flex items-center text-current hover:text-green-500 pl-3">
    <FaWhatsapp className="text-4xl" />
    <a href="https://wa.me/919819600149" target="_blank" rel="noopener noreferrer" className="text-current text-lg ml-3 hover:text-green-500">
      <strong>9819600149</strong>
    </a>
  </li>
</ul>
              </div>
               <div className="z-10 relative h-full max-md:min-h-[350px]">
                      <iframe
                          src="https://maps.google.com/maps?q=KK+Chambers,+Fort,+Mumbai&t=&z=13&ie=UTF8&iwloc=&output=embed"  
                          className="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                          allowFullScreen
                      ></iframe>
                      </div>
                      </div>
                           
                  
      <Footer></Footer>
    </>
  );
};

export default Contact;
