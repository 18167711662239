import React, { useState } from 'react';
import './style.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import MyImage from '../../images/logo.png';

const Navbar: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="navbar bg-accent-content text-base-100 font-sans font-semibold md:text-xl lg:text-2xl  md:h-32 sm:h-28 xs:h-16 ">

      <div className="navbar-start">
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost lg:hidden"
            onClick={toggleDropdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
                // Change the color here:
                stroke="#ffff" // Change this to your desired color
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className={`menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-accent-content rounded-box w-52 ${
              isDropdownOpen ? '' : 'hidden'
            }`}
          >
            <li><Link to="/">Home</Link></li>
            <li className='navr'>
              <a>Products</a>
              <ul className="p-2">
                <li><a>Plaster Boards</a></li>
                <li><Link to="/plaster" >Plaster &amp; Bond</Link></li>
              </ul>
            </li>
            <li><Link to="/accessories" >Framing & Accessories</Link></li>
            <li><Link to="/contact" >Contact Us</Link></li>
          </ul>
        </div>
        <a className="btn btn-ghost text-lg md:text-xl lg:text-2xl w-auto h-32 md:h-40 sm:h-36  pl-4 md:pl-20 pt-2 md:pt-4 xs:h-20 z-10" href="/">
  <img src={MyImage} alt="Button Image" className="w-full h-full object-cover" />
       </a>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
        <li><Link to="/">Home</Link></li>
          <li>
            <details>
              <summary >Products</summary>
              <ul className="p-2 bg-accent-content w-52 z-10">
                <li><Link to="/PlasterBoards">Plaster Boards</Link></li>
                <li><Link to="/plaster">Plaster &amp; Bond</Link></li>
              </ul> 
            </details>
          </li>
          <li><Link to="/accessories" >Framing & Accessories</Link></li>
          <li><Link to="/contact" >Contact Us</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
