
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./components/Home/Home";
import Contact from './components/Contact/Contact';
import Plaster from './components/PlasterProduct/Plaster';
import Bond from './components/Bond/Bond';
import Royalplus from './components/Productpage/Royalplus/Royalplus';
import Royalmr from './components/Productpage/RoyalMR/RoyalMR';
import Royal from './components/Productpage/Royal/Royal';
import Accessories from './components/Accessories/Accessories';
import { useEffect } from 'react';
import Aos from 'aos';
import Royalbond from './components/Productpage/Bond/Bond';
import Plasterboard from './components/Plasterboard/Plasterboard';

import Standard from './components/Plasterboardspage/Standard/Standard';
import Sheet from './components/Plasterboardspage/Sheethr/sheet';
import Paperless from './components/Plasterboardspage/Paperless/Paperless';


function App() {

  useEffect(() => {
    Aos.init();
  }, []);

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/plaster" element={<Plaster/>} /> 
        <Route path="/Royalplus" element={<Royalplus/>} />
        <Route path="/Royalhr" element={<Royalmr/>} />
        <Route path="/Royal" element={<Royal/>} />
        <Route path="/RoyalBond" element={<Royalbond/>} />
        <Route path="/Accessories" element={<Accessories/>} />
        <Route path="/PlasterBoards" element={<Plasterboard/>} />

        <Route path="/Standard" element={<Standard/>} />
        <Route path="/Sheet" element={<Sheet/>} />
        <Route path="/Paperless" element={<Paperless/>} />
      </Routes>   
    </Router>
  );
}

export default App;
